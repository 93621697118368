@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100vh;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-popup-content-wrapper {
  background-color: #2F2E33;
  color: #F4EDE3;
  border-radius: 0.75rem;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.leaflet-popup-tip {
  background-color: #2F2E33;
}
